import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

import Layout from '../components/Layout';
import Stage from '../components/Stage';
import Section from '../components/Section';
import Container from '../components/Container';
import Timeline from '../components/Timeline';
import Intro from '../components/Intro';
import { P, Headline } from '../components/Typography';
import ContactTeaser from '../components/ContactTeaser';
import PersonBox from '../components/PersonBox';

import { getImageFromNode } from '../helpers/utils';

/** Bild im Rechts-Links-Wechsel */
const Img = styled(GatsbyImage)`
    width: 100%;
`;

const AboutUsPage = ({ data }) => {
    const { stageImage, contentImages, personBoxImages } = data;
    return (
        <Layout title="Über uns">
            <Stage image={stageImage.childImageSharp} title="Über uns" />
            <Section>
                <Container size="l" grid cols="2" alignItems="center" reverseOnMobile>
                    <Intro headline="Wir sind Gottwald Elektrotechnik">
                        <P>
                            Wir sind ihr kompetenter und professioneller Elektrofachbetrieb und
                            stehen unseren Kunden und Interessenten in Zimmerbach und Umgebung schon
                            seit über 30 Jahren mit Rat und Tat zur Seite. Auf eine persönliche und
                            individuelle Betreuung unserer Kunden legen wir sehr großen Wert – so
                            sorgt unser Familienbetrieb dafür, dass sich die Wünsche unserer Kunden
                            nach einer zeitgemäßen Elektroinstallation erfüllen werden – mit Erfolg!
                        </P>
                    </Intro>
                    <Img
                        fluid={
                            getImageFromNode(contentImages, 'about-us/contentimage-intro.jpg').node
                                .childImageSharp.fluid
                        }
                    />
                </Container>
            </Section>
            <Section bg="gray" container="l">
                <Headline level="h2" gap="xxl">
                    Firmengeschichte
                </Headline>
                <Timeline
                    milestones={[
                        {
                            year: '1971',
                            description: 'Lehre zum Elektroinstallateur Adolf Gottwald',
                        },
                        { year: '1981', description: 'Gründung Gottwald & Jung Montagebetrieb' },
                        { year: '1990', description: 'Gottwald Montage ' },
                        { year: '2001', description: 'Gottwald Elektro & Montagebetrieb ' },
                        {
                            year: '2001',
                            description: 'Ausbildung zum Elektroinstallateur Christian Gottwald',
                        },
                        {
                            year: '2009',
                            description: 'Meisterprüfung Elektrotechnik Christian Gottwald ',
                        },
                        { year: '2009', description: 'Fortbildung zum Solarteur Adolf Gottwald ' },
                        { year: '2017', description: 'Erster Auszubildender Patrick Steinbrück ' },
                        {
                            year: '2019',
                            description:
                                'Elektroniker für Energie und Gebäudetechnik Patrick Steinbrück Abschluss mit Belobigung',
                        },
                    ]}
                />
            </Section>
            <ContactTeaser />
            <Section>
                <Headline level="h2" gap="xl" textAlign="center">
                    Team
                </Headline>
                <Container size="l" grid cols="3">
                    <PersonBox
                        name="Adolf Gottwald"
                        title="Meister für Elektrotechnik, Gebäudeautomation, Lichttechnik, Photovoltaik, Tortechnik, Solateur, Geschäftsführer, Betriebsinhaber"
                        image={
                            getImageFromNode(
                                personBoxImages,
                                'about-us/personboximage-adolf-gottwald.jpg'
                            ).node.childImageSharp
                        }
                    />
                    <PersonBox
                        name="Christian Gottwald"
                        title="Meister für Elektrotechnik, Gebäudeautomation, Lichttechnik, Photovoltaik, Geschäftsführer"
                        image={
                            getImageFromNode(
                                personBoxImages,
                                'about-us/personboximage-christian-gottwald.jpg'
                            ).node.childImageSharp
                        }
                    />
                    <PersonBox
                        name="Patrick Steinbrück"
                        title="Techniker für Elektrotechnik, Gebäudeautomation, Lichttechnik, Photovoltaik, staatlich geprüfter Techniker Maschinenbau"
                        image={
                            getImageFromNode(
                                personBoxImages,
                                'about-us/personboximage-patrick-steinbrueck.jpg'
                            ).node.childImageSharp
                        }
                    />
                    <PersonBox
                        name="Edith Gottwald"
                        title="Office & Projektmanagement, Assistenz Geschäftsführung"
                        image={
                            getImageFromNode(
                                personBoxImages,
                                'about-us/personboximage-edith-gottwald.jpg'
                            ).node.childImageSharp
                        }
                    />
                    <PersonBox
                        name="Christina Mezger"
                        title="Technische Zeichnungen, Bauzeichnungen, Photovoltaik-Konzepte"
                        image={
                            getImageFromNode(
                                personBoxImages,
                                'about-us/personboximage-christina-mezger.jpg'
                            ).node.childImageSharp
                        }
                    />
                </Container>
            </Section>
        </Layout>
    );
};

AboutUsPage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        stageImage: file(relativePath: { glob: "about-us/stage-image.jpg" }) {
            ...StageFluid
        }
        personBoxImages: allFile(
            filter: { relativePath: { glob: "about-us/personboximage-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    ...PersonBoxFluid
                }
            }
        }
        contentImages: allFile(
            filter: { relativePath: { glob: "about-us/contentimage-*.jpg" } }
            sort: { fields: [relativePath], order: ASC }
        ) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        fluid(maxWidth: 585, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;

export default AboutUsPage;
